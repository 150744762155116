<template>
  <div v-myTitle :data-title="textTitle">
    <div class="exam">
    <div v-if="type == 2" style="text-align: left;font-size: 14px;margin-left: 20px">
      被评者：{{ staff.name }}
    </div>
    <div
      class="examLists"
      v-for="(items, index) in examList"
      :key="index"
      v-show="nowIndex == index"
    >
      <div class="nobone">第{{ index + 1 }}题：{{ items.content }}</div>
    </div>
    <div class="question">
      <div
        :class="['selection', currentIndex == index ? 'selected' : '']"
        v-for="(item, index) in questList"
        :key="index"
        @click="selected(index)"
      >
        {{ item }}
      </div>
    </div>
    <div
      class="levelCont"
      v-if="
        (examList[nowIndex] &&
          type == 1 &&
          examList[nowIndex].level_show == 3) ||
          (examList[nowIndex] &&
            type == 2 &&
            examList[nowIndex].level_show != 1)
      "
    >
      <div class="nobone">问题重要性</div>
      <div class="question">
        <div
          :class="['selection', 'red', questionIndex == index ? 'quested' : '']"
          v-for="(item, index) in levelList"
          :key="index"
          @click="quested(index)"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="change">
      <button class="last" @click="lastEvent" v-if="nowIndex != 0">
        上一题
      </button>
      <button
        class="last"
        @click="nextEvent"
        v-if="nowIndex < examList.length - 1"
      >
        下一题
      </button>
      <button
        class="last"
        @click="doneover"
        v-if="nowIndex == examList.length - 1"
      >
        完成评分
      </button>
    </div>
  </div>
  </div>
</template>

<script>
import { getQuestion, stroeRecord, updateResult } from '@/request/api'

export default {
  name: "exam",
  data() {
    return {
      textTitle: this.$title,
      questList: ["出色", "优秀", "良好", "一般", "薄弱", "较差"],
      levelList: [
        "毫不相干",
        "极不重要",
        "不重要",
        "比较重要",
        "非常重要",
        "极其重要",
      ],
      nowIndex: 0,
      currentIndex: -1,
      questionIndex: -1,
      type: "",
      levelShow: false,
      examList: [],
      recordList: [],
      staff: []
    };
  },
  created() {
    getQuestion().then((res) => {
      console.log(res.data, 'examList');
      this.examList = res.data;
    });
    this.type = this.$store.state.userInfo.type;
    if (this.type == 2) {
      this.staff = this.$store.state.staffInfo
    }
  },
  methods: {
    selected(index) {
      this.currentIndex = index;
    },
    quested(index) {
      this.questionIndex = index;
    },
    doneover() {
      if (this.currentIndex == -1) {
        this.$toast("请选择！");
      }
      if (this.currentIndex != -1) {
        let item = this.examList[this.nowIndex];
        item.record = this.currentIndex + 1;
        let staff_id = 0
        if (this.type == 2) {
          staff_id = this.$store.state.staffInfo.id
        }
        stroeRecord({
          result_id: this.$store.state.resultId,
          question_id: item.id,
          level: item.level,
          answer: item.record,
          staff_id: staff_id
          // eslint-disable-next-line no-unused-vars
        }).then((res) => {
          updateResult({
            result_id: this.$store.state.resultId
            // eslint-disable-next-line no-unused-vars
          }).then((ret) => {
            this.$router.replace("/cobweb");
          });
        });

      }
    },
    lastEvent() {
      if (this.nowIndex > 0) {
        this.nowIndex--;
        console.log(this.nowIndex);
        this.currentIndex = this.recordList[this.nowIndex].record - 1;
      }
    },
    nextEvent() {
      if (this.currentIndex == -1) {
        this.$toast("请选择！");
      } else {
        let item = this.examList[this.nowIndex];
        // item.id = this.examList[this.nowIndex].id;
        item.record = this.currentIndex + 1;
        console.log(item, 'item')
        if (this.recordList[this.nowIndex]) {
          this.recordList[this.nowIndex] = item;
        } else {
          this.recordList.push(item);
        }
        console.log(this.recordList[this.nowIndex], 'record');
        if (this.nowIndex < this.examList.length - 1) {
          this.nowIndex++;
          if (this.recordList[this.nowIndex]) {
            this.currentIndex = this.recordList[this.nowIndex].record - 1;
          } else {
            this.currentIndex = -1;
          }
        }
        let staff_id = 0;
        if (this.type == 2) {
          staff_id = this.$store.state.staffInfo.id
        }
        stroeRecord({
          result_id: this.$store.state.resultId,
          question_id: item.id,
          level: item.level,
          answer: item.record,
          staff_id: staff_id
        }).then((res) => {
          console.log(res)
        });
        console.log(this.recordList);
      }
    },
  },
};
</script>

<style>
.exam {
  text-align: left;
}
.nobone {
  width: 90%;
  margin: 30px auto;
  height: 40px;
}

.question {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.selection {
  width: 90%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: rgb(70, 148, 238);
  margin-top: 10px;
  color: rgb(221, 247, 110);
  margin-left: 5%;
  font-weight: bold;
}
.red {
  background-color: red;
}
.selected {
  border: 2px solid rgb(219, 100, 100);
  background-color: rgb(219, 100, 100);
  box-sizing: border-box;
}
.quested {
  border: 2px solid rgb(101, 125, 231);
  box-sizing: border-box;
}
.change {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 30px auto;
}
.last {
  width: 45%;
  height: 30px;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 0 0 0 2px rgb(255, 255, 255), 0 0 5px 3px rgb(37, 161, 221);
  background-image: linear-gradient(#00B3F1, #00A3EF);
}
</style>
